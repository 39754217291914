import {call, put} from 'redux-saga/effects';
import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {apiUdsDetails} from '../api/uds';

import {
  actionFetchRangeUdsSuccess,
  actionFetchRangeUdsFailure
} from '../actions/uds';

export function* sagaActivityUds() {
  try {
    yield put(actionFetchData());
    let tickets = yield call(apiUdsDetails);
    yield put(actionFetchRangeUdsSuccess(tickets));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchRangeUdsFailure());
    yield put(actionFetchDataFailure());
  }
}