import * as actionTypes from '../constantes/devices';


export const actionFetchDevices = () => ({
  type: actionTypes.FETCHING_DEVICES_LIST,
});

export const actionFetchDevicesSuccess = payload => ({
  type: actionTypes.FETCHING_DEVICES_SUCCESS,
  payload,
});

export const actionFetchDevicesFailure = () => ({
  type: actionTypes.FETCHING_DEVICES_FAILURE,
});


export const actionFetchAddDevice = (payload) => ({
  type: actionTypes.FETCHING_DEVICE_ADD_DEVICE,
  payload
});

export const actionFetchAddDeviceSuccess = payload => ({
  type: actionTypes.FETCHING_DEVICE_ADD_SUCCESS,
  payload,
});

export const actionFetchAddDeviceFailure = () => ({
  type: actionTypes.FETCHING_DEVICE_ADD_FAILURE,
});


export const actionFetchUpdateDevice = (id, payload) => ({
  type: actionTypes.FETCHING_DEVICE_EDIT_DEVICE,
  id, payload
});

export const actionFetchUpdateDeviceSuccess = payload => ({
  type: actionTypes.FETCHING_DEVICE_EDIT_SUCCESS,
  payload,
});

export const actionFetchUpdateDeviceFailure = () => ({
  type: actionTypes.FETCHING_DEVICE_EDIT_FAILURE,
});
