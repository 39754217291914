import * as actionTypes from '../constantes/uds';


export const actionFetchUds = () => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS,
});

export const actionFetchRangeUdsSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS_SUCCESS,
  payload,
});

export const actionFetchRangeUdsFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_UDS_FAILURE,
});