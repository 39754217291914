import {applyMiddleware, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import funcionPrimaria from './sagas/index';
import reducers from './reducers';

const logger = createLogger();
const sagaMiddleware = createSagaMiddleware();

const middleware = process.env.NODE_ENV === 'production' ?   [thunk, sagaMiddleware]: [thunk, sagaMiddleware, logger];

const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

const configureStore = createStoreWithMiddleware(reducers);
sagaMiddleware.run(funcionPrimaria);


export default configureStore;
