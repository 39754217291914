import {getDateFormatDDMMYYY, getDateFormatDDMMYYYDate, toDate} from './dates';
import {convertToLiters, isSensor, truncateToTwoDecimals, getPercentGas} from './devices';
export const parseActivityRange = (history, device) => {
    let _activity = [];
    for (let i = 0; i < history.length; i++) {
        const item = history[i];
        let value = Object.values(item)[0];
        const date = toDate(Object.keys(item)[0]);

        if (value !== 666 ) {
            if (isSensor(device.id, 'C') || isSensor(device.id, 'T')){
                const {height, capacity, diameter} = device.dimentions;
                value = convertToLiters(value, height, capacity, diameter);
                _activity.push({
                    date,
                    value
                })
            }else if(isSensor(device.id, 'G')){
                const {capacity} = device.dimentions;
                const liters =truncateToTwoDecimals(getPercentGas(capacity,value), 2);
                _activity.push({
                    date,
                    value,
                    liters
                })
            }else{
                _activity.push({
                    date,
                    value
                })
            }            
        }
    }
    return _activity;
};


export const parseActivityDailyEnergy = (week) => {
    week = week.reverse();
    let _activity   = {};
    _activity.week  = [];
    _activity.max   = 0;
    _activity.total   = 0;


    for (let i = 0; i < week.length-1; i++) {
        const day = week[i];
        const nextDay = week[i+1];

        let _day = getDateFormatDDMMYYY(Object.keys(day)[0]).format('ddd');

        let _value = (Object.values(day)[0])/100;
        let _next_value = (Object.values(nextDay)[0])/100;

        const value = truncateToTwoDecimals((_value - _next_value),2);

        if (value > _activity.max){
            _activity.max = value;
        }
        _activity.total+=parseFloat(value);

        _activity.week.push({
            day: _day,
            value
        })
    }
    _activity.total= truncateToTwoDecimals(_activity.total,2);
    _activity.week=_activity.week.reverse();

    return _activity;
};

export const parseActivityDailyLastEnergyReport = (history) => {
    history = history.reverse();
    let _activity   = [];

    for (let i = 0; i < history.length-1; i++) {
        const day = history[i];
        const nextDay = history[i+1];

        let date = getDateFormatDDMMYYYDate(Object.keys(day)[0]);

        let _value = (Object.values(day)[0])/100;
        let _next_value = (Object.values(nextDay)[0])/100;

        const value = truncateToTwoDecimals((_value - _next_value),2);

        _activity.push({
            date,
            value
        })
    }
    return _activity;
};
