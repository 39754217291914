import {FETCHING_DEVICE_ADD_DEVICE, FETCHING_DEVICE_EDIT_DEVICE, FETCHING_DEVICES_LIST} from '../constantes/devices';
import {FETCHING_ACTIVITY_DAILY_DEVICE, FETCHING_ACTIVITY_RANGE_DEVICE} from '../constantes/activity';
import {FETCHING_ACTIVITY_TICKET_UDS} from '../constantes/tickets';
import {FETCHING_ACTIVITY_UDS} from '../constantes/uds';

import CONSTANTES_LOGIN from '../constantes/session';
import {sagaActivityDaily, sagaActivityRange} from './activity';
import {sagaDeviceAdd, sagaDevices, sagaDeviceUpdate} from './devices';
import {sagaActivityTicketsRange} from './tickets';
import {sagaActivityUds} from './uds'
import {sagaLoginUser} from './session';
import {takeEvery} from 'redux-saga/effects';


export default function* functionPrimaria() {
  yield takeEvery(CONSTANTES_LOGIN.FETCHING_USER_LOGIN, sagaLoginUser);
  yield takeEvery(FETCHING_DEVICES_LIST, sagaDevices);
  yield takeEvery(FETCHING_DEVICE_ADD_DEVICE, sagaDeviceAdd);
  yield takeEvery(FETCHING_DEVICE_EDIT_DEVICE, sagaDeviceUpdate);
  yield takeEvery(FETCHING_ACTIVITY_RANGE_DEVICE, sagaActivityRange);
  yield takeEvery(FETCHING_ACTIVITY_DAILY_DEVICE, sagaActivityDaily);
  yield takeEvery(FETCHING_ACTIVITY_TICKET_UDS, sagaActivityTicketsRange);
  yield takeEvery(FETCHING_ACTIVITY_UDS, sagaActivityUds);
}
