import * as actionTypes from '../constantes/request';

const initialState = {
  error: null,
  loanding: false,
};

const reducerFetchData = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DATA:
      return { error: null, loanding: true };
    case actionTypes.FETCHING_DATA_SUCCESS:
      return { error: null, loanding: false };
    case actionTypes.FETCHING_DATA_FAILURE:
      return { error: action.error, loanding: false };
    default:
      return state;
  }
};

export default reducerFetchData;
