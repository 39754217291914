import {call, put} from 'redux-saga/effects';
import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {activityTicketsRange} from '../api/tickets';

import {
  actionFetchRangeTicketSuccess,
  actionFetchRangeTicketFailure
} from '../actions/tickets';

export function* sagaActivityTicketsRange({device, start, end}) {
  try {
    yield put(actionFetchData());
    let tickets = yield call(activityTicketsRange, device, start, end);
    yield put(actionFetchRangeTicketSuccess(tickets));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchRangeTicketFailure());
    yield put(actionFetchDataFailure());
  }
}