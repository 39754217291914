import {reducerFetchDevices, reducerFetchDevicesAdd, reducerFetchDevicesEdit} from './devices';
import {reducerFetchActivityDaily, reducerFetchActivityRange} from './activity';
import {reducerFetchTicketRange} from './tickets'
import {reducerFetchUds} from './uds'
import ReducerFetchLogin from './session';
import reducerFetchData from './request';
import {combineReducers} from 'redux';
import reducerMenu from './menu';

const rootReducer = combineReducers({
  reducerMenu,
  reducerFetchData,
  ReducerFetchLogin,
  reducerFetchDevices,
  reducerFetchDevicesAdd,
  reducerFetchDevicesEdit,
  reducerFetchActivityDaily,
  reducerFetchActivityRange,
  reducerFetchTicketRange,
  reducerFetchUds
});

export default rootReducer;
