import * as actionTypes from '../constantes/devices';

const initialStateDevicesList = {
  payload: [],
  loanding: false,
};

export const reducerFetchDevices = (state = initialStateDevicesList, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DEVICES_LIST:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_DEVICES_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_DEVICES_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};

const initialStateDevicesAdd = {
  payload: {},
  loanding: false,
};

export const reducerFetchDevicesAdd = (state = initialStateDevicesAdd, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DEVICE_ADD_DEVICE:
      return { payload: action.payload, loanding: true };
    case actionTypes.FETCHING_DEVICE_ADD_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_DEVICE_ADD_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};

const initialStateDevicesEdit = {
  payload: {},
  loanding: false,
};

export const reducerFetchDevicesEdit = (state = initialStateDevicesEdit, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_DEVICE_EDIT_DEVICE:
      return { payload: action.payload, loanding: true };
    case actionTypes.FETCHING_DEVICE_EDIT_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_DEVICE_EDIT_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};

