import * as actionTypes from '../constantes/tickets';


export const actionFetchTicketDevice = (device, start, end) => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_UDS,
  device, start, end
});

export const actionFetchRangeTicketSuccess = payload => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_SUCCESS,
  payload,
});

export const actionFetchRangeTicketFailure = () => ({
  type: actionTypes.FETCHING_ACTIVITY_TICKET_FAILURE,
});