import * as actionTypes from '../constantes/tickets';

const initialStateRange = {
  payload: [],
  loanding: false,
};

export const reducerFetchTicketRange = (state = initialStateRange, action) => {
  switch (action.type) {
    case actionTypes.FETCHING_ACTIVITY_TICKET_UDS:
      return { payload: [], loanding: true };
    case actionTypes.FETCHING_ACTIVITY_TICKET_SUCCESS:
      return { payload: action.payload, loanding: false };
    case actionTypes.FETCHING_ACTIVITY_TICKET_FAILURE:
      return { payload: action.payload, loanding: false };
    default:
      return state;
  }
};
