import LOGIN_CONSTANTES from '../constantes/session';

const initialState = {
  isFetchin: false,
  error: false,
  payload: {
    profile_pic: 'https://cdn3.f-cdn.com/contestentries/1376995/30494909/5b566bc71d308_thumb900.jpg',
    name: 'Mauricio',
    user: 'hola@harimhome.com'
  }
};

const reducerFetchLogin = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_CONSTANTES.FETCHING_USER_LOGIN:
      return { isFetchin: true, error: false, payload:initialState.payload };
    case LOGIN_CONSTANTES.FETCHING_USER_LOGIN_SUCCESS:
      return { isFetchin: false, error: false, payload: action.payload };
    case LOGIN_CONSTANTES.FETCHING_USER_LOGIN_FAILURE:
      return { isFetchin: false, error: true, payload:initialState.payload};
    case LOGIN_CONSTANTES.FETCHING_USER_LOGOUT:
      return initialState;
    case LOGIN_CONSTANTES.FETCHING_USER_LOGOUT_SUCCESS:
      return initialState;
    case LOGIN_CONSTANTES.FETCHING_USER_LOGOUT_FAILURE:
      return initialState;
    default:
      return state;
  }
};

export default reducerFetchLogin;
