import {call, put} from 'redux-saga/effects';

import {activityDeviceDaily, activityDeviceRange} from '../api/activity';
import {actionFetchData, actionFetchDataFailure, actionFetchDataSuccess} from '../actions/request';
import {parseActivityDailyLastEnergyReport} from '../helpers/activity';
import PNotify from "pnotify/dist/es/PNotify";
import "pnotify/dist/es/PNotifyButtons";
import "pnotify/dist/es/PNotifyConfirm";
import "pnotify/dist/es/PNotifyCallbacks";
import {
    actionFetchDailyDeviceFailure,
    actionFetchDailyDeviceSuccess,
    actionFetchRangeDeviceFailure,
    actionFetchRangeDeviceSuccess
} from '../actions/activity';

export function* sagaActivityRange({device, start, end}) {
  try {
    yield put(actionFetchData());
    let devices = yield call(activityDeviceRange, device, start, end);
    yield put(actionFetchRangeDeviceSuccess(devices));
    yield put(actionFetchDataSuccess());
    if(!devices.length){
      _notifiyNoData();
    }
  } catch (error) {
    yield put(actionFetchRangeDeviceFailure());
    yield put(actionFetchDataFailure());

  }
}


export function* sagaActivityDaily({device, start, end}) {
  try {
    yield put(actionFetchData());
    let devices = yield call(activityDeviceDaily, device, start, end);
    devices = parseActivityDailyLastEnergyReport(devices);
    yield put(actionFetchDailyDeviceSuccess(devices));
    yield put(actionFetchDataSuccess());
  } catch (error) {
    yield put(actionFetchDailyDeviceFailure());
    yield put(actionFetchDataFailure());
  }
}



function _notifiyNoData() {
  PNotify.info({
    title: "Aviso",
    text: "No se encuentran registros en el rango de fechas",
    modules: {
      Desktop: {
        desktop: true
      }
    }
  });
}