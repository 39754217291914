import {call, put} from 'redux-saga/effects';
import {actionFetchLoginFailure, actionFetchLoginSuccess,} from '../actions/session';
import {apiLoginUsers} from '../api/session';
import {parseUser} from '../helpers/session';


export function* sagaLoginUser() {
  try {
    const userResponse = yield call(apiLoginUsers);
    const user = parseUser(userResponse);
    yield put(actionFetchLoginSuccess(user));
    console.log('::: Saga User ::::', user);
  } catch (error) {
    yield put(actionFetchLoginFailure(error));
    console.log('ERROR:::', error);
  }
}
