
import sensorTanque from '../../assets/images/devices/TANQUE.svg';
export const example = (example) => `Hola ${example}`;

export const parseDevices = (devices) => {
    let _parseDevices = [];
    
   
    devices.forEach(device => {
        _parseDevices.push(
            {
                id: device,
                name: `Carburación Demo`,
                img: sensorTanque,
                date: new Date().getTime() / 1000,
            }
        )
    });

    return _parseDevices;
};