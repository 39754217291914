import { request } from './request';
import { dateToUnix } from '../helpers/dates'
export const apiDevicesDetails = () => {
    return JSON.parse('{ "G007112UKLA": { "'+dateToUnix(new Date())+'":90, "battery": "27/05/2020", "capacity": "5000","name": "Demo"}}'); 
};


export const apiDevicesAdd = (id) => {
    return request('put', `devices/${id}`, {})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiDevicesDelete = (id) => {
    return request('delete', `devices/${id}`, {})
        .then((response) => response.data)
        .catch(error => error)
};


export const apiDevicesUpdate = (id, params) => {
    return request('put', `devices/${id}/details`, params)
        .then((response) => response.data)
        .catch(error => error)
};


