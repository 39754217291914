  export const FETCHING_DEVICES_LIST        = 'FETCHING_DEVICES_LIST';
  export const FETCHING_DEVICES_SUCCESS     = 'FETCHING_DEVICES_SUCCESS';
  export const FETCHING_DEVICES_FAILURE     = 'FETCHING_DEVICES_FAILURE';


  export const FETCHING_DEVICE_ADD_DEVICE   = 'FETCHING_DEVICE_ADD_DEVICE';
  export const FETCHING_DEVICE_ADD_SUCCESS  = 'FETCHING_DEVICE_ADD_SUCCESS';
  export const FETCHING_DEVICE_ADD_FAILURE  = 'FETCHING_DEVICE_ADD_FAILURE';

  export const FETCHING_DEVICE_EDIT_DEVICE   = 'FETCHING_DEVICE_EDIT_DEVICE';
  export const FETCHING_DEVICE_EDIT_SUCCESS  = 'FETCHING_DEVICE_EDIT_SUCCESS';
  export const FETCHING_DEVICE_EDIT_FAILURE  = 'FETCHING_DEVICE_EDIT_FAILURE';
